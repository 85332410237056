import { useEffect, useState } from "react";
import NotAvailableComponent from "../NotAvailableComponent";
import ProgressBar from "@ramonak/react-progress-bar";
import InvoiceItemElement from "./InvoiceItemElement";
import { FaInfoCircle } from "react-icons/fa";
import { TiWarning } from "react-icons/ti";
import { IoQrCode } from "react-icons/io5";

import { useAuth } from "../../../context/AuthContext";
import { BusinessEstablishment } from "../../../PropTypes/AdminDashboardProps";
import SelectorElement from "../SelectorElement";

const InvoiceAndSubscriptionCard = (props: {
  openedTabIndexAnimationInvoker: number;
  businessEstablishment: any;
  invoiceDetailsSection: any;
  invoices: any;
  clients: BusinessEstablishment[]
}) => {
  const [subscriptionPeriod, setSubscriptionPeriod] = useState(props.businessEstablishment.invoice_period);
  const [subscriptionPeriodStart, setSubscriptionPeriodStart] = useState(props.businessEstablishment.subscription_period_start);
  const [subscriptionPeriodEnds, setSubscriptionPeriodEnds] = useState(props.businessEstablishment.subscription_period_end);
  const [subscriptionPeriodDaysLeft, setSubscriptionPeriodDaysLeft] = useState(0); // Stav pre progresivný bar
  const [progress, setProgress] = useState(0); // Stav pre progresivný bar
  const [progressMax, setProgressMax] = useState(0); // Stav pre progresivný bar
  const [daysLeftStyle, setDaysLeftStyle] = useState({});
  const [daysAfter, setDaysAfter] = useState(0);
  const [invoiceProgressText, setInvoiceProgressText] = useState("");
  const [dynamicProgressClass, setDynamicProgressClass] = useState("");

  const [latestInvoice, setLatestInvoice] = useState<any>()
  const [newInvoicePeriod, setNewInvoicePeriod] = useState<any>()

  //@ts-ignore
  const { user } = useAuth()

  const getReadableSubscriptionPeriod = (period: string) => {
    switch (period) {
      case "FREE_TRIAL":
        return "14 dní zadarmo";
      case "3_MONTHS":
        return "3 mesiace";
      case "6_MONTHS":
        return "6 mesiacov";
      case "12_MONTHS":
        return "12 mesiacov";
      default:
        break;
    }
  };

  const addDaysAndFormat = (dateString: string, days: number) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + days);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
}

  const generatePayMeLink = () => {
    const subscription_price = 11

    const iban = "SK6409000000005145072366"
    let text = "3+mesiace"
    let eur = 3 * 11

    if (newInvoicePeriod === "12_MONTHS") {
      eur = 12 * 11
      text = "12+mesiacov"
    } else if (newInvoicePeriod === "6_MONTHS") {
      eur = 6 * 11
      text = "6+mesiacov"
    } else {
      eur = 3 * subscription_price
      text = "3+mesiace"
    }
    const date = addDaysAndFormat(latestInvoice.subscription_period_end, 14) /* "20250202" */
    const variable_symbol = latestInvoice.variable_symbol

    const url = `https://payme.sk/?V=1&IBAN=${iban}&AM=${eur}&CC=EUR&DT=${date}&PI=%2FVS${variable_symbol}%2FSS%2FKS&MSG=MojeQR+-+predplatne+na+${text}&CN=MojeQr+-+Tom%C3%A1%C5%A1+Me%C5%BEdej`
    window.open(url)
  }


  const findClosestSubscription = (data: BusinessEstablishment[]) => {
    const today = new Date();

    return data.reduce((closest, current) => {
        const currentDate = new Date(current.subscription_period_end);
        const closestDate = new Date(closest.subscription_period_end);
        //@ts-ignore
        return (Math.abs(currentDate - today) < Math.abs(closestDate - today)) ? current : closest;
    });
}

  const getLatestInvoice = () => {
    const closestSubscription = findClosestSubscription(props.clients)

    const client = closestSubscription.name
    const date_transformed = new Date(closestSubscription.subscription_period_end)
    const date = date_transformed.toLocaleDateString("sk-SK", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    })

    setLatestInvoice({
      ...closestSubscription,
      text: `${client} - vystaviť ku dňu ${date}`
    })
    setNewInvoicePeriod(closestSubscription.invoice_period)
  }

  useEffect(() => {
    getLatestInvoice();
  }, []);

  useEffect(() => {
    setSubscriptionPeriod(props.businessEstablishment.invoice_period);
    const startDate = new Date(props.businessEstablishment.subscription_period_start);
    const endDate = new Date(props.businessEstablishment.subscription_period_end);
    const today = new Date();

    today.setHours(0, 0, 0, 0); // Nastavenie času na 1:00
    startDate.setHours(0, 0, 0, 0); // Nastavenie času na 1:00
    endDate.setHours(0, 0, 0, 0); // Nastavenie času na 1:00
console.log(today)
    setSubscriptionPeriodEnds(
      endDate.toLocaleDateString("sk-SK", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      })
    );
    setSubscriptionPeriodStart(
      startDate.toLocaleDateString("sk-SK", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      })
    );

    // Celkový počet dní predplatného
    const totalDays = Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));
    // Zostávajúce dni
    const daysLeft = Math.max(0, Math.round((endDate.getTime() - today.getTime()) / (1000 * 3600 * 24)));
    const localDaysAfter = Math.round((today.getTime() - endDate.getTime()) / (1000 * 3600 * 24));
    // Počet dní, ktoré uplynuli
    const elapsedDays = totalDays - daysLeft;
    setDaysAfter(localDaysAfter)
console.log(daysLeft)
    // Výpočet progresu ako percentuálnej hodnoty
    const progressPercentage = Math.min(100, Math.max(0, (elapsedDays / totalDays) * 100));

    setProgress(Math.round(progressPercentage)); // Nastavenie progresu v percentách
    setProgressMax(100); // Maximálna hodnota progresu
    setSubscriptionPeriodDaysLeft(daysLeft);

    // Nastavenie štýlov a textov podľa zostávajúcich dní
    if (daysLeft > 15) {
      setDaysLeftStyle({ fontWeight: "500", color: "#399C08" });
      //@ts-ignore
      setInvoiceProgressText(<>Zostáva&nbsp;<span style={daysLeftStyle}>{daysLeft}</span>&nbsp;dní</>);
    } else if (daysLeft <= 15 && daysLeft > 0) {
      setDaysLeftStyle({ fontWeight: "500", color: "#FF810C" });
      setDynamicProgressClass("progress_alert");
      //@ts-ignore
      setInvoiceProgressText(<>Zostáva už len&nbsp;<span style={daysLeftStyle}>{daysLeft}</span>&nbsp;dní</>);
      if (daysLeft >= 2 && daysLeft <= 4) {
        //@ts-ignore
        setInvoiceProgressText(<>Zostávajú už len&nbsp;<span style={daysLeftStyle}>{daysLeft}</span>&nbsp;dni</>);
      }
      if (daysLeft === 1) {
        //@ts-ignore
        setInvoiceProgressText(<>Zostáva už len&nbsp;<span style={daysLeftStyle}>{daysLeft}</span>&nbsp;deň</>);
      }
    } else if (daysLeft === 0) {
      setDaysLeftStyle({ fontWeight: "500", color: "#FF810C" });
      setInvoiceProgressText("Vaše predplatné končí dnes");
      setDynamicProgressClass("progress_alert");
    }
    console.log(daysAfter)
    if (daysAfter > 0) {
      setDaysLeftStyle({ fontWeight: "500", color: "#BD282B" });
      setInvoiceProgressText("Máte nezaplatenú faktúru");
      setDynamicProgressClass("progress_unpaid");
    }
  }, [props.businessEstablishment, daysAfter]);


  return (
    <div
      className="invoices_wrapper"
      style={{
        transform: `${props.openedTabIndexAnimationInvoker !== 4 ? "translateY(100vh)" : "translateY(0vh)"}`,
      }}
    >
      <div className="transparent_card">
        <div className="title">Faktúry a predplatné</div>
      </div>
      {
        user?.is_superuser &&
        <div className="sunscription_info_wrap">
          <div className="subscription_info">
            <div
              className="card"
              style={
                {
                  marginTop: "0px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px"
                }
              }
            >
              <TiWarning color="orangered" size={"30px"} /> <div className="subtitle">Nasledujúca faktúra - </div>
              <div className="subtitle">
                {latestInvoice && latestInvoice.text}
              </div>
            </div>
            <div className="transparent_card" style={{marginLeft: "0px", marginTop: "0px"}}>
              <div
                className="card pay_me_button"

                onClick={() => generatePayMeLink()}
              >
                <IoQrCode  size={"20px"}/> Generovať QR kód
              </div>
              <SelectorElement
                selectedElement={latestInvoice?.invoice_period}
                list={
                  [
                    {
                      title: "3 mesiace",
                      value: "3_MONTHS"
                    },
                    {
                      title: "6 mesiacov",
                      value: "6_MONTHS"
                    },
                    {
                      title: "12 mesiacov",
                      value: "12_MONTHS"
                    },
                  ]
                }
                onSelect={(new_type) => { setNewInvoicePeriod(new_type)}}
              />
            </div>
            {/* <div className="card pay_me_button" onClick={() => generatePayMeLink()}>
              <IoQrCode  size={"30px"}/>
            </div> */}
          </div>
        </div>
      }
      <div className="sunscription_info_wrap">
        <div className="subscription_info">
          <div className="card subscription_period_card" style={{ marginTop: "0px", marginBottom: "0px"}}>
            { subscriptionPeriod === "FREE_TRIAL" ? "Skúšobná verzia" : "Interval predplatného:" } <br />
            <span className="subscription_period">{getReadableSubscriptionPeriod(subscriptionPeriod)}</span>
          </div>
          <div className="card subscription_period_card" style={{ marginTop: "0px", marginBottom: "0px"}}>
          { subscriptionPeriod === "FREE_TRIAL" ? "Skúšobné obdobie:" : "Predplatené obdobie:" } <br />
            <span className="subscription_period_dates">{`${subscriptionPeriodStart} - ${subscriptionPeriodEnds}`}</span>
          </div>
          {subscriptionPeriod !== "FREE_TRIAL" && (
            <div className="card subscription_period_card" style={{ marginTop: "0px", marginBottom: "0px"}}>
              Predplatné končí dňa: <br />
              <span key={subscriptionPeriodEnds} className="subscription_period" style={{color: subscriptionPeriodDaysLeft <= 0 ? "#BD282B" : subscriptionPeriodDaysLeft <= 15 ? "#FFCE08" : "#9A73EC"}}>{subscriptionPeriodEnds}</span>
            </div>
          )}
        </div>
      </div>
      <div className="card subscribtion_journey">
        <div className="subscription_info">
          { invoiceProgressText }
        </div>
        <ProgressBar
          completed={progress}
          maxCompleted={progressMax}
          className="progress_wrapper"
          labelClassName="progress_label"
          bgColor={subscriptionPeriodDaysLeft <= 0 ? "#BD282B" : subscriptionPeriodDaysLeft <= 15 ? "#FF810C" : "#9A73EC"}
          baseBgColor="#e0e0de"
          height="10px"
        />
      </div>
      <div className="invoice_list_wrap">
        <div className="transparent_card invoice_list_holder">
          <div className="title">
            Zoznam faktúr {user?.is_superuser && `- ${props.businessEstablishment.name}`}
          </div>
          <div className="transparent_card invoice_list">
            {
              props.invoices.filter((invoice: any) => invoice.belongs_to === props.businessEstablishment.uuid).length === 0 ?
              <div className="no_invoices">
                <FaInfoCircle size={"20px"} />
                Zatiaľ nemáte žiadnu faktúru.
              </div> :
              props.invoices.filter((invoice: any) => invoice.belongs_to === props.businessEstablishment.uuid).reverse().map((invoice: any, index: number) =>
                <InvoiceItemElement
                  key={index}
                  index={index}
                  invoice={invoice}
                  subscriptionStartDate={subscriptionPeriodStart}
                  subscriptionEndDate={subscriptionPeriodEnds}
                />
              )
            }
          </div>
        </div>
        <div className="card invoice_preview">
          {props.invoiceDetailsSection}
        </div>
       {/*  <NotAvailableComponent text="Zoznam faktúr momentálne nie je k dispozícii." />
        <div className="card invoice_preview">
          <NotAvailableComponent text="Náhľad faktúry momentálne nie je k dispozícii." />
        </div> */}
      </div>
    </div>
  );
};

export default InvoiceAndSubscriptionCard;
