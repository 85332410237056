
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { AiOutlineLoading } from "react-icons/ai";
import { IoEyeOutline, IoEyeOffOutline  } from "react-icons/io5";
import { invokeAlertComponent } from "../../services/userInteractionService";

const LoginCard = () => {
  const logo = require("../../assets/images/logo.jpg")
  const navigate = useNavigate()
  //@ts-ignore
  const { login } = useAuth()


  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [pendingRequest, setPendingRequest] = useState(false)

  const handleLogin = () => {
    const onSuccess = () => {
      setPendingRequest(false); // Reset pending state after success
    };

    const onError = (error: any) => {
      if (error.response.data.detail === "No active account found with the given credentials") {
        invokeAlertComponent(
          "INFORMATION_ALERT_COMPONENT",
          "Zadali ste nesprávne prihlasovacie údaje !",
          "ALERT"
        )
      } else {
        invokeAlertComponent(
          "INFORMATION_ALERT_COMPONENT",
          "Niečo sa pokazilo, zadali ste správne prihlasovacie údaje ?",
          "ALERT"
        )
      }
      setPendingRequest(false); // Reset pending state after error
    };

    if (userName && password && !pendingRequest) {
      setPendingRequest(true); // Set request to pending
      login(userName, password, onSuccess, onError); // Trigger login
    }
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter") { handleLogin() }
    }

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup listener when component unmounts
    return () => { window.removeEventListener("keydown", handleKeyDown) }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName, password, pendingRequest]);

  useEffect(() => {
    window.scrollTo({
      top: 0
    })
  }, [])


  return (
    <div className="login_card_wrap" id="LOGIN_CARD_WRAP">
      <div className="login_card_left_side">
        <div className="mobile_img_wrap">
          <div className="img_bg">
            <img src={logo} alt="MojeQR" />
          </div>
        </div>
        <div className="title_wrap">
          <span className="title">
            Vitajte
          </span>
        </div>
        <div className="global_input_wrap">
          <div className="input_wrap">
            <label htmlFor="">Užívateľské meno</label>
            <input
              value={userName}
              type="text"
              placeholder="Vaše užívateľské meno"
              onChange={(e) => {setUserName(e.target.value)}}
            />
          </div>
          <div className="input_wrap">
            <label htmlFor="">Heslo</label>
            <input
              value={password}
              type={`${showPassword ? "text" : "password"}`}
              placeholder="Vaše heslo"
              onChange={(e) => {setPassword(e.target.value)}}
            />
            <span
              className="eye_icon"
              onClick={() => setShowPassword(!showPassword)}
            >
              {
                !showPassword ? <IoEyeOffOutline className="icon" size={"18px"} color="#2C2C2C"/> : <IoEyeOutline className="icon" size={"18px"} color="#2C2C2C"/>
              }
            </span>
          </div>
          <div className="input_wrap login_button_wrap">
            <button
              disabled={pendingRequest}
              onClick={handleLogin}

            >
              Prihlásiť sa
            </button>
            <button
              onClick={() => {

                // Add the animation class to body
                document.body.classList.add("hide-slide-background");
                document.body.classList.add("scale-background")
                document.body.classList.remove("slide-background")
                setTimeout(() => {

                }, 200);
                  navigate("/")
              }}
            >
              Späť domov
            </button>

          </div>
        </div>
      </div>
      {
          pendingRequest &&
          <AiOutlineLoading
            size={"45px"}
            /* color="#8E62EA" */
            color="white"
            className="loading_icon"
          />
        }
      <div className="login_card_right_side">
        <div className="image_wrap">
          <img src={logo} alt="MojeQR" />
        </div>
        <div className="tips_wrap">
          <div className="tips">
            Nový ovládací panel je tu!
            <br />
            Využi všetky vylepšenia a nové funkcie na maximum.
          </div>
          <div className="dots">
            • • •
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginCard