/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import BenefitListElement from "../elements/BenefitListElement"

import { BiCustomize } from "react-icons/bi"
import {
  MdOutlineHandyman,
  MdTouchApp
} from "react-icons/md"
import {
  FaViruses,
  FaPhone,
  FaUserEdit
} from "react-icons/fa"
import {
  BsInstagram
} from "react-icons/bs"
import {
  ImFacebook2
} from "react-icons/im"
import {
  SiGmail
} from "react-icons/si"
import {
  phone,
  email,
  instagramLink,
  facebookLink
} from "../../config/contact"

import { GiWallet } from "react-icons/gi"
import { useNavigate } from "react-router-dom"

interface LocalProps {
  id: string
}

const ServiceCard = (props: LocalProps) => {

  const howItWorksImg_1 = require("../../assets/images/how_it_works/how_it_works_1.png")
  const howItWorksImg_2 = require("../../assets/images/how_it_works/how_it_works_2.png")
  const howItWorksImg_3 = require("../../assets/images/how_it_works/how_it_works_3.png")
  const howItWorksImg_4 = require("../../assets/images/how_it_works/how_it_works_4.png")
  const howItWorksImg_5 = require("../../assets/images/how_it_works/how_it_works_5.png")
  const howItWorksImg_6 = require("../../assets/images/how_it_works/how_it_works_6.png")


  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const benefitListRef = useRef(null);

  const en = require("../../locales/en.json")
  const sk = require("../../locales/sk.json")

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language === "sk" ? sk : en)
  const [isInViewport, setIsInViewport] = useState(false)
  const [scrollPositionFromTop, setScrollPosition] = useState(0)

  const getIcon = (index: number) => {
    switch (index) {
      case 0:

        return BiCustomize;
      case 1:

        return MdOutlineHandyman;
      case 2:

        return FaViruses;
      case 3:

        return MdTouchApp;
      case 4:

        return GiWallet;
      case 5:

        return FaUserEdit;

      default:
        return BiCustomize;
    }
  }


  useEffect(() => {
    setSelectedLanguage(i18n.language === "sk" ? sk : en)
  }, [en, i18n.language, sk])

  const handleScroll = () => {
    /* const scrollPosition = window.scrollY + 800
    if (!isInViewport) {
      setScrollPosition(scrollPosition)
    }
    const benefitListElement = document.getElementById('BENEFIT_ELEMENT_0')
    if (benefitListElement) {
      benefitListElement.style.transform = `translateX(${scrollPositionFromTop - scrollPosition}px)`
    } */
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    /* const handleIntersection = () => {
      //@ts-ignore
      const rect = benefitListRef.current?.getBoundingClientRect()
      if (rect) {
        const isVisible = (
          rect.top >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + 500
        )
        setIsInViewport(isVisible)
      }
    };

    window.addEventListener('scroll', handleIntersection);
    handleIntersection() */


    return () => {

      /* window.removeEventListener('scroll', handleIntersection) */
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isInViewport])

  return (
    <div
      id={ props.id }
      className="serviceCard"
    >
      <h1
        id="SERVICES_TITLE_ID"
      >
        { t("serviceCard.services") }
      </h1>
      <div className="about_card">
        <h3>
          { t("serviceCard.about") }
        </h3>
      </div>
      <div className="serviceCard__benefit_list_wrap" ref={benefitListRef}>
        <div className="benefit_row">
          <div className="element" id={`BENEFIT_ELEMENT_5`}>
            <div
              style={{order: 2}}
            >
              <FaUserEdit
                className="icon"
                size={"60px"}
              />
            </div>
            <div
              style={{order: 1}}
            >
              <h2>
              { t("serviceCard.adminTitle") }
              </h2>
              {/* <img src={require("../../assets/images/panel_preview_1.png")} style={{width: "100%"}} alt="" /> */}
              <p>
              { t("serviceCard.adminText") }
              </p>
              {/* <img src={require("../../assets/images/panel_preview_2.png")} style={{width: "100%"}} alt="" /> */}
            </div>
          </div>
        </div>
        <div className="benefit_row">
          <div className="" id={`BENEFIT_ELEMENT_5`}>
            <div
              style={{order: 1}}
            >
              {/* <FaUserEdit
                className="icon"
                size={"60px"}
              /> */}
            </div>
            <div
              style={{order: 2}}
            >
              <img src={require("../../assets/images/panel_preview_1.png")} style={{width: "100%"}} alt="" />

            </div>
          </div>
        </div>
        <div className="benefit_row">
          <div className="" id={`BENEFIT_ELEMENT_5`}>
            <div
              style={{order: 2}}
            >
              {/* <FaUserEdit
                className="icon"
                size={"60px"}
              /> */}
            </div>
            <div
              style={{order: 1}}
            >
              <img src={require("../../assets/images/panel_preview_2.png")} style={{width: "100%"}} alt="" />

            </div>
          </div>
        </div>
        {
          selectedLanguage.serviceCard.benefits.map((benefit: any, index: number) =>
            <div className="benefit_row" key={index}>
              <BenefitListElement
                key={index}
                id="BENEFIT_ONE_ID"
                index={index}
                icon={getIcon(index)}
                title={benefit.title}
                description={benefit.description}
              />
            </div>
          )
        }

      </div>
      <div className="serviceCard__layouts_wrap">
        <h2>
          { t("serviceCard.howItWorks.title") }
        </h2>
        <div className="serviceCard__timeline">
          <div
            className="timeline_row"
            style={{
              marginLeft: "0px"
            }}
          >
            <div>
              <img src={howItWorksImg_1}  alt="Ako to funguje - Krok 1" />
            </div>
            <div className="">
              <h2>
                { t("serviceCard.howItWorks.one.title") }
              </h2>
              <p>
                { t("serviceCard.howItWorks.one.text1") } <a onClick={() => {navigate("/kontakt")}}>{ t("serviceCard.howItWorks.one.text2") }</a> { t("serviceCard.howItWorks.one.text3") }
              </p>
              <div className="socials">
                <BsInstagram
                  className="icon"
                  size={"25px"}
                  onClick={() => {window.open(instagramLink, '_blank')}}
                />
                <ImFacebook2
                  className="icon"
                  size={"25px"}
                  onClick={() => {window.open(facebookLink, '_blank')}}
                />
                <a href={`tel:${phone}`}>
                  <FaPhone
                    className="icon"
                    size={"25px"}
                  />
                </a>
                <a href={`mailto:${email}`}>
                  <SiGmail
                    className="icon"
                    size={"25px"}
                  />
                </a>
              </div>
            </div>
          </div>

          <div
            className="timeline_row"
            style={{
              marginLeft: "55px"
            }}
          >
            <div>
              <img src={howItWorksImg_2}  alt="Ako to funguje - Krok 2" />
            </div>
            <div className="">
              <h2>{ t("serviceCard.howItWorks.two.title") }</h2>
              <p>
                { t("serviceCard.howItWorks.two.text") }
              </p>
            </div>
          </div>

          <div
            className="timeline_row"
            style={{
              marginLeft: "110px"
            }}
          >
            <div>
              <img src={howItWorksImg_3}  alt="Ako to funguje - Krok 3" />
            </div>
            <div className="">
              <h2>{ t("serviceCard.howItWorks.three.title") }</h2>
              <p>
                { t("serviceCard.howItWorks.three.text") }
              </p>
            </div>
          </div>

          <div
            className="timeline_row"
            style={{
              marginLeft: "165px"
            }}
          >
            <div>
              <img src={howItWorksImg_4}  alt="Ako to funguje - Krok 4" />
            </div>
            <div className="">
              <h2>{ t("serviceCard.howItWorks.four.title") }</h2>
              <p>
                { t("serviceCard.howItWorks.four.text") }
              </p>
            </div>
          </div>
          <div
            className="timeline_row"
            style={{
              marginLeft: "220px"
            }}
          >
            <div>
              <img src={howItWorksImg_5}  alt="Ako to funguje - Krok 5" />
            </div>
            <div className="">
              <h2>{ t("serviceCard.howItWorks.five.title") }</h2>
              <p>
                { t("serviceCard.howItWorks.five.text") }
              </p>
            </div>
          </div>
          <div
            className="timeline_row"
            style={{
              marginLeft: "275px"
            }}
          >
            <div>
              <img src={howItWorksImg_6}  alt="Ako to funguje - Krok 6" />
            </div>
            <div className="">
              <h2>{ t("serviceCard.howItWorks.six.title") }</h2>
              <p>
                { t("serviceCard.howItWorks.six.text") } <span className="bold">{ t("serviceCard.howItWorks.six.textBold") }</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="serviceCard__layouts_wrap">
        <h2>
          { t("serviceCard.howItLooksLike") }
        </h2>
        <img src={howItLooksLikeImg} alt="MojeQr - Ako to vyzerá"/>
      </div> */}
    </div>
  )
}

export default ServiceCard