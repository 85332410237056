import NotAvailableComponent from "../NotAvailableComponent";
import { useContext, useState, useRef, useEffect } from "react";
import { StatsContext } from "../../cards/AdminDashboardCard";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import SelectorElement from "../SelectorElement";

// Registrácia modulov pre Chart.js
Chart.register(...registerables);

const StatsCard = () => {
  const chartRef = useRef<any>(null);
  const selectedDaysRef = useRef("30");
  const context = useContext(StatsContext);

  const [selectedDays, setSelectedDays] = useState("30")
  const [statsData, setStatsData] = useState([])

  // API log data
  //@ts-ignore
  const apiLogData = /* context.stats || []; */statsData

  // Vytvorenie objektu s počtom načítaní na každý dátum
  const getDateCounts = (data: any) => {
    return data.reduce((acc: any, item: any) => {
      const date = new Date(item.timestamp).toLocaleDateString("sk-SK");
      if (acc[date]) {
        acc[date].number_of_loads += 1;
      } else {
        acc[date] = {
          date,
          number_of_loads: 1,
        };
      }
      return acc;
    }, {});
  };

  // Premeníme objekt na pole
  const dateCounts = Object.values(getDateCounts(apiLogData));

  // Generovanie dát pre graf
  //@ts-ignore
  const inner_data = dateCounts.map(item => item.number_of_loads)

  const data = {
    //@ts-ignore
    labels: dateCounts.map(item => item.date),
    datasets: [
      {
        label: "Počet zobrazení",
        data: inner_data,
        borderColor: (context: any) => {
          const { ctx, chartArea } = context.chart;
          if (!chartArea) return;
          const gradientFill = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
          gradientFill.addColorStop(0, "rgba(148, 106, 235, 1)");
          gradientFill.addColorStop(1, "rgba(148, 106, 235, 0.8)");
          return gradientFill;
        },
        backgroundColor: (context: any) => {
          const { ctx, chartArea } = context.chart;
          if (!chartArea) return;
          const gradientFill = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
          gradientFill.addColorStop(0, "rgba(148, 106, 235, 0.6)");
          gradientFill.addColorStop(1, "rgba(148, 106, 235, 0.8)");
          return gradientFill;
        },
        borderWidth: 2,
        tension: 0.4,
        fill: true,
      },
    ],
  };

  const maxY = Math.max(...inner_data)
  const y_options = {
    title: { display: false, text: "Počet záznamov" },
    beginAtZero: true,
    ticks: {
      stepSize: 1,
    }
  }
  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
    },
    scales: {
      x: { title: { display: false, text: "Dátum" } },
      y: maxY >= 10 ? y_options : {...y_options, max: maxY + 1},
    },
  };

  // Nastavenie gradientu pri načítaní komponentu
  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.ctx;
      const { chartArea } = chartRef.current;

      if (chartArea) {
        const gradientFill = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradientFill.addColorStop(0, "rgba(148, 106, 235, 0.6)");
        gradientFill.addColorStop(1, "rgba(148, 106, 235, 1)");
      }
    }
  }, []);

  useEffect(() => {
    if(selectedDaysRef.current !== "props.default_value") {
      //@ts-ignore
      context.getStats(setStatsData,parseInt(selectedDaysRef.current))
    }
    //@ts-ignore
  }, [context.currentClient])


  return (
    <>

      <div className="row stats_row">

        <div className="card w-75">
          <Line ref={chartRef} data={data} options={options} />
        </div>
        <div className="transparent_card w-25" style={{ marginTop: "0px", marginBottom: "0px" }}>
          <div className="transparent_card" style={{marginLeft: "0px", marginRight: "0px"}}>
            <SelectorElement
              selectedElement={selectedDays}
              list={
                [
                  {
                      title: "Za posledných 30 dní",
                      value: "30"
                  },
                  {
                      title: "Za posledných 15 dní",
                      value: "15"
                  },
                  {
                      title: "Za posledných 7 dní",
                      value: "7"
                  }
                ]
              }
              onSelect={(new_days) => {
                /* setSelectedDays(new_days) */
                if(new_days !== "props.default_value") {
                  //@ts-ignore
                  context.getStats(setStatsData,parseInt(new_days))
                }
                selectedDaysRef.current = new_days
              }}
            />
          </div>
          <div className="achievement_card card margin-sides-0" style={{ marginTop: "0px", marginBottom: "0px" }}>
            <img src={require("../../../assets/images/achievement.png")} alt="MojeQr - Achievement" />
            <span className="number">{apiLogData.length}</span>
            <span className="description">Počet zobrazení</span>
            {/* <NotAvailableComponent
              text="Počet zobrazení spolu"
              customWrapStyle={{ minHeight: "0px" }}
              customStyleImage={{ textAlign: "center", maxWidth: "120px" }}
              customStyleTitle={{ fontSize: "18px", lineHeight: "20px", textAlign: "center", fontWeight: "500", maxWidth: "210px" }}
            /> */}
          </div>
          <div className="card margin-sides-0" style={{ marginTop: "0px" }}>
            <NotAvailableComponent
              text="Na ďalších štatistikách pracujeme"
              customWrapStyle={{ minHeight: "0px" }}
              customStyleImage={{ textAlign: "center", maxWidth: "120px" }}
              customStyleTitle={{ fontSize: "18px", lineHeight: "20px", textAlign: "center", maxWidth: "210px" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StatsCard;
