import { CreateElementBlockModalProps, OfferSectionElement } from "../../../PropTypes/AdminDashboardProps"
import { IoClose } from "react-icons/io5";
import { IoEyeOutline, IoEyeOffOutline  } from "react-icons/io5";
import { CgFormatSeparator } from "react-icons/cg";
import { FaStar  } from "react-icons/fa";
import { CiImageOn } from "react-icons/ci";
import { invokeAlertComponent } from "../../../services/userInteractionService";
import { useEffect, useState } from "react";
import SelectorElement from "../SelectorElement";



const CreateElementBlockModal = (props: CreateElementBlockModalProps) => {
  const defaultOfferBlock = {
    belongs_to: props.businessEstablishment!.uuid, // Replace with the actual value or default
    block_image: null,  // Replace with the actual selected image object
    block_image_width: 50,    // Use default value if not provided
    block_title_long: "", // Default value or selected title
    block_title_short: "", // Default value or selected title
    block_position_row: 1, // Default value or selected row position
    block_position_column: "BOTH_COLUMNS", // Default value or selected column choice,
    block_is_visible: true,
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [localImage, setLocalImage] = useState("")
  const [editingMode, setEditingMode] = useState(false)
  const [offerBlockImageOffer, setOfferBlockImageOffer] = useState(false)
  const [newOfferBlock, setNewOfferBlock] = useState(defaultOfferBlock);


  const handleClose = () => {
    props.onClose()
  }

  const handleConfirm = () => {
    if (!newOfferBlock.block_title_long) {
      setNewOfferBlock({...newOfferBlock, block_title_long: newOfferBlock.block_title_short})
    }
    //@ts-ignore
    props.onConfirm(newOfferBlock)
  }


  /* const handleUploadImage = async () => {
    // Open file input to select an image
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*"; // Allow only image files

    input.addEventListener("change", async (event) => {
      //@ts-ignore
      const selectedFile = event.target.files[0];

      if (selectedFile) {
        // Step 1: Convert the image file to Base64
        const reader = new FileReader();

        reader.onloadend = async () => {
          // The Base64-encoded string will be in reader.result
          const base64String = reader.result as string;
          setNewOfferBlock({ ...newOfferBlock, block_image: base64String });
        };

        reader.onerror = (error) => {
          console.error("Error reading file:", error);
        };

        // Read the image as a Base64 string
        reader.readAsDataURL(selectedFile);
      }
    });

    input.click(); // Trigger file input to open
  } */

    const handleUploadImage = async (image: any) => {
      const imageUrl = `${process.env.REACT_APP_MOJEQR_API_URL}${image.icon}`

      // Fetch the image as a Blob
      /* const response = await fetch(imageUrl);
      const blob = await response.blob();

      const file = new File([blob], 'image.jpg', { type: blob.type });
 */
      //@ts-ignore
      setNewOfferBlock({ ...newOfferBlock, block_image: image.uuid });
      setLocalImage(`${process.env.REACT_APP_MOJEQR_API_URL}${image.icon}`)
      invokeAlertComponent("INFORMATION_ALERT_COMPONENT", "Obrázok bol úspešne vybraný!");

    }

    useEffect(() => {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Escape") { handleClose() }
      }

      window.addEventListener("keydown", handleKeyDown);

      // Cleanup listener when component unmounts
      return () => { window.removeEventListener("keydown", handleKeyDown) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    useEffect(() => {
      if (props.selectedElement) {
        //@ts-ignore
        setNewOfferBlock(props.selectedElement)
          //@ts-ignore
        if (props.selectedElement.block_image !== "null") {
          //@ts-ignore
          setLocalImage(`${process.env.REACT_APP_MOJEQR_API_URL}${props.selectedElement.block_image.icon}`)
        }
        console.log(props.selectedElement)
        setEditingMode(true)
      } else {
        setEditingMode(false)
      }
    }, [])

  return(
    <div
      className="confirmation_modal create_element_modal create_element_block_modal card_element"
      /* onClick={() => props.onClose()} */
    >
      <div className="modal" onClick={(event) => {event.stopPropagation()}}>
        <IoClose
          className="close_icon"
          onClick={(event) => {
            event.stopPropagation()
            handleClose()
          }}
          size={"25px"}
          color="#2C2C2C"
        />
        <div className="content">
          <div className="title_row">
            Nastavenia sekcie
            {/* {
              props.selectedElement ? "Upravte sekciu" : "Vytvorte novú sekciu"

            } */}
          </div>
          {/* <div className="field_element add_after_element_selector_wrap">
            <label>Umiestnenie položky</label>
            <SelectorElement
              list={sectionList}
              onSelect={(selected) => { handleOrderChange(selected) }}
            />
          </div> */}
          <div className="section_row">
          {/*   section selector */}
          </div>
          <div className="fields_wrap">
            <div className="fields_col_1">
              <div className="field_element">
                <label htmlFor="ELEMENT_ID_NAME">Názov na telefóne</label>
                <div className="field card_element">
                  <input
                    id="ELEMENT_ID_NAME"
                    value={newOfferBlock?.block_title_short}
                    type="text"
                    placeholder="Zadajte krátky názov"
                    onChange={(e) => {
                      setNewOfferBlock({...newOfferBlock, block_title_short: e.target.value})
                    }}
                  />
                </div>
              </div>
              <div className="field_element">
                <label htmlFor="ELEMENT_ID_NAME">Názov na počítači</label>
                <div className="field card_element">
                  <input
                    id="ELEMENT_ID_NAME"
                    value={newOfferBlock?.block_title_long}
                    type="text"
                    placeholder="Zadajte dlhý názov"
                    onChange={(e) => {
                      setNewOfferBlock({...newOfferBlock, block_title_long: e.target.value})
                    }}
                  />
                </div>
              </div>
              <div className="field_element">
                <div className="checkbox_wrap" style={{marginTop: "15px"}}>
                  <div
                    className="card_element checkbox"
                    onClick={
                      () => { setNewOfferBlock({...newOfferBlock, block_is_visible: !newOfferBlock.block_is_visible}) }
                    }
                  >
                    {
                    !newOfferBlock.block_is_visible ?
                      <IoEyeOffOutline className="icon" size={"15px"} color="#2C2C2C" />
                      :
                      <IoEyeOutline className="icon" size={"15px"} color="#2C2C2C" />
                    }
                  </div>
                  { newOfferBlock.block_is_visible ? "Schovať sekciu" : "Zobraziť sekciu"}
                </div>
              </div>
              {/* <div className="field_element"
                style={{
                  opacity: (element.item_type === "ITEM_REGULAR" || element.item_type === "description") ? 1 : 0.2,
                  cursor: (element.item_type === "ITEM_REGULAR" || element.item_type === "description") ? "pointer" : "default",
                  transition: "0.3s"
                }}
              >
                <label htmlFor="ELEMENT_ID_DESCR">Popis</label>
                <div className="field description_card_element card_element">
                  <textarea
                    id="ELEMENT_ID_DESCR"
                    value={element.item_description}
                    placeholder="Zadajte popis"
                    disabled={(element.item_type !== "ITEM_REGULAR" && element.item_type !== "description")}
                    rows={6}
                    onChange={(e) => {setNewSection({...element, item_description: e.target.value})}}
                  />
                </div>
              </div> */}
              <button
                className="button"
                style={{
                  marginTop: "10px"
                }}
                onClick={(event) => {
                  event.stopPropagation()
                  handleConfirm()
                }}
              >
                Potvrdiť
              </button>
            </div>

            <div className="fields_col_2">
                {/* <div className="field_element">
                  <label>Číslo riadka</label>
                  <div className="field card_element">
                    <input
                      id="ELEMENT_ID_NAME"
                      value={newOfferBlock.block_position_row}
                      type="number"
                      placeholder="Zadajte číslo riadka"
                      onChange={(e) => {
                        setNewOfferBlock({...newOfferBlock, block_position_row: parseInt(e.target.value)})
                      }}
                    />
                  </div>
                </div> */}
                {/* <div className="field_element">
                  <label>Pozícia v riadku</label>
                  <SelectorElement
                    selectedElement={
                      //@ts-ignore
                      props.selectedElement?.block_position_column
                    }
                    list={
                      [
                        {
                          title: "Na celú šírku",
                          value: "BOTH_COLUMNS"
                        },
                        {
                          title: "Ľavý stĺpec",
                          value: "LEFT_COLUMN"
                        },
                        {
                          title: "Pravý stĺpec",
                          value: "RIGHT_COLUMN"
                        }
                      ]
                    }
                    onSelect={(new_type) => { setNewOfferBlock({...newOfferBlock, block_position_column: new_type}) }}
                  />
                </div> */}
                {/* <div className="field_element">
                  <div className="checkbox_wrap" style={{marginTop: "35px"}}>
                    <div
                      className="card_element checkbox"
                      onClick={
                        () => { setNewOfferBlock({...newOfferBlock, block_is_visible: !newOfferBlock.block_is_visible}) }
                      }
                    >
                      {
                      !newOfferBlock.block_is_visible ?
                        <IoEyeOffOutline className="icon" size={"15px"} color="#2C2C2C" />
                        :
                        <IoEyeOutline className="icon" size={"15px"} color="#2C2C2C" />
                      }
                    </div>
                    { newOfferBlock.block_is_visible ? "Schovať sekciu" : "Zobraziť sekciu"}
                  </div>
                </div> */}
              {/* <div className="field_element">
                <label>Farba ikonky</label>
                <SelectorElement
                  list={
                    [
                      {
                        title: "Čierna ikona",
                        value: "BLACK"
                      },
                      {
                        title: "Biela ikona",
                        value: "WHITE"
                      }
                    ]
                  }
                  onSelect={(new_type) => { setNewOfferBlock({...newOfferBlock, block_image_color: new_type}) }}
                />
              </div> */}

            </div>

            <div className="fields_col_3">
              <div className="field_element">
                <label>Šírka ikonky</label>
                <div className="field card_element">
                  <input
                    id="ELEMENT_ID_NAME"
                    value={newOfferBlock.block_image_width}
                    type="number"
                    placeholder="Zadajte šírku ikonky"
                    onChange={(e) => {
                      setNewOfferBlock({...newOfferBlock, block_image_width: parseInt(e.target.value)})
                    }}
                  />
                </div>
              </div>

              <div className="field_element">
                  <div
                    className="field card_element selected_icon_offer_block"
                    style={{
                      opacity: 1,
                      cursor: "pointer",
                      transition: "0.3s"
                    }}
                    onClick={ () => {setOfferBlockImageOffer(prev => !prev)} }
                  >
                    {
                      localImage ?
                      <img
                        src={localImage}
                        alt="Selected Icon"
                      />
                      :
                      <CiImageOn
                        className="icon"
                        size={"55px"}
                        color="white"
                      />
                    }
                    Vybrať ikonku
                  </div>
              </div>

            </div>
          </div>

        </div>

      </div>
      <div
        className={`offer_block_icon_card card_element ${offerBlockImageOffer ? "offer_block_icon_card_visible" : "offer_block_icon_card_hidden"}`}
      >

        {
          props.offerBlockImages && props.offerBlockImages.map((image: any, index: number) =>
            <div
              key={index}
              className="offer_block_icon"
              onClick={() => {
                handleUploadImage(image)
                setOfferBlockImageOffer(false)
              }}
            >
              {
                image.icon &&
                <img
                  src={`${process.env.REACT_APP_MOJEQR_API_URL}${image.icon}`}
                  alt="Icon"
                />
              }
            </div>
          )
        }
      </div>
    </div>
  )
}

export default CreateElementBlockModal