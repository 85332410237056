import { useEffect } from "react"
import ContactCard from "../cards/ContactCard"

const BlogArticleVyhodyOnlineMenu = (props: any) => {


    useEffect(() => {
      document.title = props.meta.title
      document.getElementsByTagName('meta')[3].content = props.meta.content
      document.getElementsByTagName('link')[2].href =props.meta.href
    },[])

  return (
    <div className="blogCard_article">
      <div className="title_row">
        <div className="title">
          <h1>Výhody QR Menu pre Reštaurácie</h1>
        </div>
      </div>
      <div className="content_row">

        <div className="content_row_element_v1">
          {/* <div className="subtitle">
            subtitle
          </div> */}
          <div className="text_image_row">
            <p>
              Digitalizácia zasahuje takmer do každej oblasti našich životov, a reštauračný
              priemysel nie je výnimkou. QR kódy, ktoré boli kedysi využívané
              hlavne na marketingové účely, si teraz našli miesto aj na stoloch v reštauráciách.
              Ak zvažujete zavedenie QR menu vo vašej reštaurácii, tu je prehľad hlavných výhod,
              ktoré tento systém ponúka:
            </p>
            {/* <div>
              image here
            </div> */}
          </div>
        </div>

        <div className="content_row_element_v1">
          <div className="subtitle">
            <h2>
              1. Eliminácia Tlačených Menu
            </h2>
          </div>
          <div className="text_image_row">
            <div>
              <p>
                QR menu eliminuje potrebu tlačených jedálnych lístkov, ktoré si prezerá veľa ľudí.
                Vaši hostia si jednoducho nasnímajú QR kód svojim telefónom a okamžite majú prístup k aktuálnemu menu.
              </p>
            </div>
            {/* <div>
              image here
            </div> */}
          </div>
        </div>

        <div className="content_row_element_v1">
          <div className="subtitle">
            <h2>
              2. Jednoduchá Aktualizácia Menu
            </h2>
          </div>
          <div className="text_image_column">
            <div>
              <p>
                Jednou z najväčších nevýhod tradičných tlačených menu je ich častá neaktuálnosť.
                Pri QR menu je akákoľvek zmena v ponuke rýchla a jednoduchá. Stačí upraviť digitálny
                dokument alebo webovú stránku a hostia okamžite vidia aktuálne informácie.
              </p>
            </div>
            <div>
              <img src={require("../../assets/images/panel_preview_1.png")} alt="Jednoduchá Aktualizácia Menu" />
            </div>
          </div>
        </div>

        <div className="content_row_element_v1">
          <div className="subtitle">
            <h2>
              3. Zážitok pre Zákazníka
            </h2>
          </div>
          <div className="text_image_row">
            <div>
              <p>
                QR menu môže ponúknuť viac ako len zoznam jedál a nápojov.
                Môžete ho obohatiť o fotografie jedál, videá, alergény alebo dokonca odporúčania na
                kombinácie jedál a nápojov. Týmto spôsobom môžete zlepšiť celkový zážitok zákazníka.
              </p>
            </div>
            {/* <div>
              image here
            </div> */}
          </div>
        </div>

        <div className="content_row_element_v1">
          <div className="subtitle">
            <h2>
              4. Šetrenie Nákladov
            </h2>
          </div>
          <div className="text_image_row">
            <div>
              <p>
                Tlač nových menu pri každej zmene stojí peniaze. QR menu je investíciou, ktorá sa
                rýchlo vráti, pretože eliminuje potrebu opakovaných tlačených aktualizácií.
                Okrem toho prispieva k udržateľnosti, čo je v dnešnej dobe pre mnohých hostí dôležité.
              </p>
            </div>
            {/* <div>
              image here
            </div> */}
          </div>
        </div>

        <div className="content_row_element_v1">
          <div className="subtitle">
            <h2>
              5. Efektívnosť a Rýchlosť
            </h2>
          </div>
          <div className="text_image_row">
            <div>
              <p>
                Používanie QR menu urýchľuje proces obsluhy.
                Hostia nemusia čakať, kým im obsluha prinesie tlačené menu – stačí im ich vlastný smartfón.
                Týmto spôsobom sa minimalizuje čas potrebný na objednávanie a personál sa môže sústrediť na poskytovanie lepších služieb.
              </p>
            </div>
            {/* <div>
              image here
            </div> */}
          </div>
        </div>

        <div className="content_row_element_v1">
          <div className="subtitle">
            <h2>
              6. Možnosť Integrácie s Objednávkovými Systémami
            </h2>
          </div>
          <div className="text_image_row">
           <div>
              <p>
                Moderné QR menu je možné prepojiť s objednávkovými a platobnými systémami.
                Hostia si môžu objednať priamo z mobilu a v niektorých prípadoch dokonca zaplatiť.
                Týmto spôsobom sa znižuje zaťaženie obsluhy.
              </p>
            </div>
            {/* <div>
              image here
            </div> */}
          </div>
        </div>

        <div className="content_row_element_v1">
          <div className="subtitle">
            <h2>
              7. Marketingové Možnosti
            </h2>
          </div>
          <div className="text_image_row">
            <div>
              <p>
                QR kódy sú skvelým nástrojom na marketing.
                Po naskenovaní môžete hostí presmerovať nielen na menu, ale aj na špeciálne akcie, newsletter alebo sociálne siete.
                To zvyšuje šancu na ich opätovnú návštevu.
              </p>
            </div>
            {/* <div>
              image here
            </div> */}
          </div>
        </div>

        <div className="content_row_element_v1">
          <div className="subtitle">
            <h2>
              8. Analýzy a Štatistiky
            </h2>
          </div>
          <div className="text_image_column">
            <div>
              <p>
                Digitálne systémy QR menu často ponúkajú analytické nástroje, ktoré vám
                umožňujú sledovať, ktoré jedlá sú najobľúbenejšie, čo hostia najviac prezerajú a ako
                interagujú s vašou ponukou.
              </p>
              <p>
                MojeQR tiež poskytuje podrobné štatistiky o tom, koľkokrát bol
                váš QR kód naskenovaný a v akom čase. Tieto údaje môžete využiť na optimalizáciu menu, lepšie
                pochopenie správania zákazníkov a efektívnejšie rozhodovanie.
              </p>
            </div>
            <div>
              <img src={require("../../assets/images/panel_preview_2.png")} alt="Analýzy a Štatistiky" />
            </div>
          </div>
        </div>

        <div className="content_row_element_v1">
          <div className="subtitle">
            <h2>Záver</h2>
          </div>
          <div className="text_image_row">
            <div>
              <p>
                QR menu nie je len trend, ale efektívne riešenie, ktoré prináša mnoho výhod pre reštaurácie aj ich zákazníkov.
                Zlepšuje hygienu, zákaznícky zážitok a znižuje náklady. Ak chcete držať krok s modernými technológiami a pritiahnuť
                nových hostí, QR menu by malo byť súčasťou vašej reštaurácie.
              </p>
            </div>
            {/* <div>
              image here
            </div> */}
          </div>
        </div>

        {/* <div className="content_row_element_v2">
          <div className="subtitle">
            subtitle
          </div>
          <div className="text_image_column">
            <p>
              text here
            </p>
            <div>
              image
            </div>
          </div>
        </div> */}

      </div>

    </div>
  );
}

export default BlogArticleVyhodyOnlineMenu;
