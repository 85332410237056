import React from "react";
import HtmlParser from "../../services/htmlParser"
import { GiChiliPepper } from "react-icons/gi"
import { BiShowAlt } from "react-icons/bi"
import { FaQuestionCircle } from "react-icons/fa";
/* import { useEffect } from "react"; */


import FlagElement from "../global/FlagElement"
import { item_icons } from "../../config/itemIcons";

interface localProps {
  element: {
    uuid: string,
    item_name: string,
    item_description: string,
    item_volume: string,
    item_price: string,
    flag_behind_title?: boolean,
    flag?: string,
    flagHeight?: string,
    blockId?: string,
    divide_after: boolean,
    lastElement?: boolean,
    item_type?: "HEADLINE_REGULAR" | "HEADLINE_ICON" | "HEADLINE_DESCRIPTION" | "ITEM_REGULAR" | "ITEM_REGULAR_WITH_ICON" | "HEADLINE_ICON_DESCRIPTION" | "HEADLINE_ICON_DESCRIPTION" | "ITEM_SEPARATOR"
    typeAfter?: "HEADLINE_REGULAR" | "HEADLINE_DESCRIPTION",
    item_icon?: any,
    iconSize?: string,
    iconColor?: string,
    spicyLevel?: 0 | 1 | 2 | 3,
    item_without_description?: boolean,
    item_expanded_description?: any,
    use_flag_1?: boolean,
    use_flag_2?: boolean,
    use_flag_3?: boolean,
  },
  recommended_flag: string,
  localConfig: any
}





const ItemElementOnline = (props: localProps) => {

  const getElementIcon = (icon: string) => {
    //@ts-ignore
    if (icon && item_icons[icon] && icon !== "item_icon") {
      //@ts-ignore
      return item_icons[icon]; // Return the icon component based on the name
    }
  };
  /* const handleLeftClickImage = () => {
    alert("left")
  }
  const handleRightClickImage = () => {
    alert("right")
  } */


  const manageParagraphs = (arrayOfParagraphs: Array<string>, item_expanded_descriptionContent:HTMLElement, closing: true | false) => {
    if (arrayOfParagraphs) {
      if (closing) {
        for (let index = 0; index < arrayOfParagraphs.length; index++) {
          document.getElementById(`EXPANDABLE_DESCRIPTION_PARAGRAPH_${index}`)?.remove()
        }
        document.getElementById("IMAGE_MODAL_EXPANDABLE_CONTENT")!.style.display = "none"
      } else {
        document.getElementById("IMAGE_MODAL_EXPANDABLE_CONTENT")!.style.display = "block"
        for (let index = 0; index < arrayOfParagraphs.length; index++) {
          const element =arrayOfParagraphs[index]
          const paragraph = document.createElement("p")
          paragraph.setAttribute("id", `EXPANDABLE_DESCRIPTION_PARAGRAPH_${index}`)
          paragraph.textContent = element
          item_expanded_descriptionContent?.appendChild(paragraph)
        }
      }
    }
  }

  const handleElementClick = () => {
    //@ts-ignore
    if (props.element.item_expanded_image || props.element.item_expanded_description) {
      const close = () => {
        // add closing animation

        modal!.style.opacity = "0"

        title!.style.opacity = "0"
        title!.style.transform = "translateY(-100vh)"

        item_expanded_descriptionContent!.style.opacity = "0"
        item_expanded_descriptionContent!.style.transform = "translateY(-100vh)"

        image!.style.transform = "translateY(-100vh)"
        setTimeout(() => {
          modal!.style.display = "none"
          image!.style.transform = "translateY(100vh)"
          title!.style.transform = "translateY(80px)"
          item_expanded_descriptionContent!.style.transform = "translateY(80px)"
        }, 500);
        //@ts-ignore
        if (!props.element.item_expanded_image && props.element.item_expanded_description) {
          manageParagraphs(props.element.item_expanded_description.split('\n'), item_expanded_descriptionContent, true)
        }
      }

      /* document.body.style.overflow = "hidden" */
      const closeButton = document.getElementById("IMAGE_MODAL_CLOSE_BUTTON_WRAP")
      const modal = document.getElementById("IMAGE_MODAL")
      const image = document.getElementById("IMAGE_MODAL_IMAGE")
      image!.style.display = "block"
      const title = document.getElementById("IMAGE_MODAL_TITLE")
      const item_expanded_descriptionContent = document.getElementById("IMAGE_MODAL_EXPANDABLE_CONTENT")!
      /* document.getElementById("IMAGE_MODAL_LEFT")?.addEventListener("click", handleLeftClickImage)
      document.getElementById("IMAGE_MODAL_RIGHT")?.addEventListener("click", handleRightClickImage) */
      document.getElementById("IMAGE_MODAL_LEFT")?.addEventListener("click", close)
      document.getElementById("IMAGE_MODAL_RIGHT")?.addEventListener("click", close)
      /* document.getElementById("IMAGE_MODAL_CLOSE_BUTTON_WRAP")?.addEventListener("click", close) */

      item_expanded_descriptionContent!.style.display = "none"
      //@ts-ignore
      if (!props.element.item_expanded_image && props.element.item_expanded_description) {
        image!.style.display = "none"
        manageParagraphs(props.element.item_expanded_description.split('\n'), item_expanded_descriptionContent, false)


        /* item_expanded_descriptionContent!.style.display = "block" */
      }

      modal!.style.display = "flex"
      // add entering animation
      setTimeout(() => {
        modal!.style.opacity = "1"
        image!.style.transform = "translateY(0vh)"
      }, 100)

      setTimeout(() => {

        title!.style.opacity = "1"
        item_expanded_descriptionContent!.style.opacity = "1"

      }, 500);
      setTimeout(() => {

        title!.style.transform = "translateY(0px)"
        item_expanded_descriptionContent!.style.transform = "translateY(0px)"
      }, 500);

      //@ts-ignore
      closeButton!.style.color = "white"
      //@ts-ignore
      image!.src = `${process.env.REACT_APP_MOJEQR_API_URL}${props.element.item_expanded_image}`
      title!.innerText = props.element.item_name

      closeButton?.addEventListener("click", close)
    }



  }




  return (
    <div
      id={`${props.element.uuid}_ID`}
      className="menu_item_element"
      onClick={handleElementClick}
    >
      <div className="title_row">

        <div
          className="title"
          style={{
            display: "flex",
            alignItems: "center",

          }}
        >
          {/* {
            //@ts-ignore
            props.element.item_expanded_image &&
            <div className="image_row">
              <img
                src={
                  //@ts-ignore
                  `${process.env.REACT_APP_MOJEQR_API_URL}${props.element.item_expanded_image}`
                }
                alt={props.element.item_name}
              />
            </div>
          } */}
          {
            (
              (
                props.element.item_type === "ITEM_REGULAR_WITH_ICON" ||
                props.element.item_type === "HEADLINE_REGULAR" ||
                props.element.item_type === "HEADLINE_ICON" ||
                props.element.item_type === "HEADLINE_DESCRIPTION" ||
                props.element.item_type === "HEADLINE_ICON_DESCRIPTION"
              ) && (props.element.item_icon && getElementIcon(props.element.item_icon))
            )  &&
            <div className="title_descr_icon_wrap">
              {(
                <div className="icon">
                  {React.createElement(getElementIcon(props.element.item_icon), {
                    className: "icon",/*
                    color: props.element.iconColor || "white", */
                    size: props.element.iconSize || "30px",
                  })}
                </div>
              )}
              {/* <props.element.item_icon
                className="icon"
                color={props.element.iconColor || "white"}
                size={props.element.iconSize || "30px"}
              />
              {getElementIcon(props.element.item_icon)} */}
            </div>
          }
          <span>
            <span style={{paddingRight: "5px"}}>{ props.element.item_name }</span>
            {
              //@ts-ignore
              props.element.item_is_recommended && props.element.flag_behind_title &&
              <>
                <FlagElement
                  flag={`${process.env.REACT_APP_MOJEQR_API_URL}${props.recommended_flag}`}
                  flagHeight={props.element.flagHeight}
                  descrLength={props.element.item_description.length}
                  flagBehindTitle={props.element.flag_behind_title}
                  //@ts-ignore
                  hasImage={props.recommended_flag}
                />
              </>
            }
            {
              props.element.flag_behind_title && props.element.use_flag_1 &&
              <img className="flag_element" src={`${process.env.REACT_APP_MOJEQR_API_URL}${props.localConfig.item_flag_1}`} alt="" />
            }
            {
              props.element.flag_behind_title && props.element.use_flag_2 &&
              <img className="flag_element" src={`${process.env.REACT_APP_MOJEQR_API_URL}${props.localConfig.item_flag_2}`} alt="" />
            }
            {
              props.element.flag_behind_title && props.element.use_flag_3 &&
              <img className="flag_element" src={`${process.env.REACT_APP_MOJEQR_API_URL}${props.localConfig.item_flag_3}`} alt="" />
            }
            {
              //@ts-ignore
              props.element.item_expanded_image &&
                <BiShowAlt
                  id="INCLUDING_IMAGE"
                  size={"15px"}
                />
            }
          </span>
          {
            (props.element.spicyLevel !== 0 && props.element.spicyLevel) && Array.from({ length: props.element.spicyLevel }, (_, index) => (
              <GiChiliPepper
                key={index}
                size={"20px"}
                style={{
                  marginLeft: index > 0 ? "-10px" : "15px"
                }}
                color="#F7C808"
              />
            ))
          }
        </div>
        {
          (
            props.element.item_type !== "HEADLINE_ICON" &&
            props.element.item_type !== "HEADLINE_DESCRIPTION" &&
            props.element.item_type !== "HEADLINE_ICON_DESCRIPTION") &&
          <div className="price">
            { props.element.item_price }
          </div>
        }
      </div>
        <div
          className="description_row"
          /* style={!props.element.item_description && !props.element.item_volume ? {
            height: "0px",
            overflow: "hidden"
          } : {}} */
        >
          {
            !props.element.item_without_description &&
            <div
              className="descr"
            >
              <HtmlParser>
                { props.element.item_description }
              </HtmlParser>
              {
                //@ts-ignore
                props.element.item_is_recommended && !props.element.flag_behind_title &&
                <>
                  <FlagElement
                    flag={`${process.env.REACT_APP_MOJEQR_API_URL}${props.recommended_flag}`}
                    flagHeight={props.element.flagHeight}
                    descrLength={props.element.item_description.length}
                  />
                </>
              }
              {
                !props.element.flag_behind_title && props.element.use_flag_1 &&
                <img className="flag_element" src={`${process.env.REACT_APP_MOJEQR_API_URL}${props.localConfig.item_flag_1}`} alt="" />
              }
              {
                !props.element.flag_behind_title && props.element.use_flag_2 &&
                <img className="flag_element" src={`${process.env.REACT_APP_MOJEQR_API_URL}${props.localConfig.item_flag_2}`} alt="" />
              }
              {
                !props.element.flag_behind_title && props.element.use_flag_3 &&
                <img className="flag_element" src={`${process.env.REACT_APP_MOJEQR_API_URL}${props.localConfig.item_flag_3}`} alt="" />
              }
              {
                //@ts-ignore
                (!props.element.item_expanded_image && props.element.item_expanded_description) &&
                <FaQuestionCircle
                  size={"15px"}
                  style={{
                    marginTop: "5px",
                    paddingLeft: "5px"
                  }}
                />
              }
            </div>
          }
          {
            props.element.item_volume && <div className="volume">
              <HtmlParser>
                { props.element.item_volume }
              </HtmlParser>
            </div>
          }
        </div>
      {
      (props.element.divide_after || props.element.item_type === "HEADLINE_REGULAR" || props.element.item_type === "HEADLINE_ICON") &&
        (
          props.element.item_type === "HEADLINE_REGULAR"
            ?
            <div
              style={{
                marginTop: "0px",
                width: "100%",
                backgroundColor: props.localConfig.dividerColor
              }}
              className="divider"
            />
            :
            <div
              style={{
                width:
                  (
                    //@ts-ignore
                    props.element.item_type === "HEADLINE_REGULAR"
                      || props.element.item_type === "HEADLINE_DESCRIPTION"
                      //@ts-ignore
                      || props.element.item_typeAfter === "HEADLINE_REGULAR"
                      //@ts-ignore
                      || props.element.item_typeAfter === "HEADLINE_DESCRIPTION"
                      //@ts-ignore
                      || props.element.item_type === "HEADLINE_REGULAR"
                  )
                      ? "100%" : "90%",
                backgroundColor: props.localConfig.dividerColor
              }}
              className="divider"
          />
        )
      }


    </div>
  )

}

export default ItemElementOnline





