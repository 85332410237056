import { IoMdArrowRoundBack } from "react-icons/io"

export const sportoveCentrumDubravka = {
  name: "Športové Centrum Dúbravka",
  path: "sportove_centrum_dubravka",
  menu: [],
  /* default logo './assets/images/clients/logos/just_lovely_cofe.png' */
  /* logo: require("../../assets/images/clients/logos/just_lovely_cofe.png"), */
  /* logo: require("../../assets/images/clients/logos/halloween/just_lovely_cofe_halloween.png"), */
  logo: require("../../assets/images/clients/logos/christmas/sportove_centrum_dubravka_christmas.png"),

  // after click on section screen scrolls to that section
  autoscrollAfterClick: false,
  scrollToTopAferOpen: true,
  // left alignment if displayed in rows
  alignTitleToLeft: false,
  //scroll time of scroll to section
  autoscrollTimeInMs: 100,
  //scroll to top of page after closing section
  scrollToTopAfterClose: true,
  //icon that appears in corner when section is opened
  hideAllIcon: IoMdArrowRoundBack,
  //decides if other sections should dissapear when section is opened
  hideAllWhenOneOpen: true,
  //vibrate on click on section
  vibrateOnClick: false,
  // close previous section when new section is opened
  closePreviousSectionOn: true,
  //margin top to display all sections
  displayAllOnPhone: false,
  //contact icon color
  iconColor: "#F704B2",
  localBackgroundColor: "#2F1BA8",
  //links to social sites
  facebook: "https://www.facebook.com/bowlingbarfkcra",
  instagram: "https://www.instagram.com/sc_dubravka/",
  email: "info@scdubravka.sk",
  phone: "+421905474530",

  //styles
  mainWrapClass: "scDubravka",
  bottomNote: ""
  /*
  logoBorderRadius: "8px",
  background: "#CFCAC4",
  blockBackgroundColor: "#878787",
  dividerColor: "#F7C808", */


}
