import BlogArticleVyhodyOnlineMenu from "../../components/blog/BlogArticleVyhodyOnlineMenu.tsx"

const blogContent = [
  {
    topic: "výhody",
    title: "Výhody QR menu pre reštaurácie",
    short_description: "Zistite, aké výhody prináša QR menu pre reštaurácie. Od šetrenia vašich nákladov, času až po zlepšenie zákazníckeho zážitku.",
    image: require("../../assets/images/panel_preview_2.png"),
    date: "18.01.2025",
    path: "vyhody_online_menu",
    component: <BlogArticleVyhodyOnlineMenu meta={{
      href: `https://www.mojeqr.sk/blog/vyhody_online_menu`,
      title: "Výhody QR menu pre reštaurácie | MojeQR",
      content: "Zistite, aké výhody prináša QR menu pre reštaurácie. Od šetrenia vašich nákladov, času až po zlepšenie zákazníckeho zážitku.",
      keywords: "QR menu, reštaurácie, digitalizácia, zákaznícky zážitok, moderné technológie"
    }}/>
  }
]

export default blogContent