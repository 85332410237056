import { FiCopy } from "react-icons/fi";
import { FaRegEdit, FaFileInvoice, FaFacebookSquare, FaQuestionCircle } from "react-icons/fa";
import { AiFillLayout, AiOutlineLoading } from "react-icons/ai";
import { IoStatsChart } from "react-icons/io5";
import { RiInstagramFill } from "react-icons/ri";
import { SiGmail } from "react-icons/si";
import { ImMobile } from "react-icons/im";
import { FaShoppingCart } from "react-icons/fa";
import { IoPeopleCircleSharp } from "react-icons/io5";
import { MdOutlinePayment } from "react-icons/md";
import { MdPeopleAlt } from "react-icons/md";

import { invokeAlertComponent } from "../../services/userInteractionService";
import { TiThMenuOutline } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai"
import { IoLogOut } from "react-icons/io5";

import { copyToClipboard } from "../../services/userInteractionService";

import { ClientSettingsCardProps, OpenedSettingsTabIndexType } from "../../PropTypes/AdminDashboardProps";
import { useAuth } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import SelectorElement from "./SelectorElement";


const NavigationCard = (props: ClientSettingsCardProps) => {

  //@ts-ignore
  const { user } = useAuth()
  const [headerOpen, setHeaderOpen] = useState(false)
  const [firstRender, setFirstRender] = useState(true)


  const mojeqr_logo = require('../../assets/images/logo.jpg')
  const [logoBadgeURL, setLogoBadgeURL] = useState(undefined)

  const getTopMargin = (selectedTab: OpenedSettingsTabIndexType) => {
    switch (selectedTab) {
      case 0:
        return 8
      case 1:
        return 41
      case 2:
        return 75
      case 3:
        return 109
      case 4:
        return 143
      case 5:
        return 180
      case 6:
        return 213
      case 7:
        return 247
      case 8:
        return 281
      default:
        break;
    }
  }


  const handleMenuOpen = () => {
    setFirstRender(false)
    setHeaderOpen(prev => {
      return !prev
    })
  }

  const sendMail = () => {
    var link = "mailto:info@mojeqr.sk"
             + "&subject=" + encodeURIComponent("MojeQR - Ovládací panel požiadavka")
    ;

    window.location.href = link;
}

  const settings_elements = [
    {
      title: "Editácia ponuky",
      onClick: () => { props.onPriceEditClick() },
      icon: <FaRegEdit size={"20px"}/>
    },
    {
      title: "Editácia rozloženia",
      onClick: () => { props.onLayoutEditClick() },
      icon: <AiFillLayout size={"20px"}/>
    },
    {
      title: "Ponuky",
      onClick: () => { props.onImageGalleryClick() },
      icon: <FaShoppingCart   size={"20px"}/>
    },
    {
      title: "Štatistiky",
      onClick: () => { props.onContactEditClick() },
      icon: <IoStatsChart  size={"20px"}/>
    },
    {
      title: "Faktúry a predplatné",
      onClick: () => { props.onInvoiceSubscriptionClick() },
      icon: <div className="menu_element"><FaFileInvoice  size={"20px"}/>{props.isUnpaidInvoice && <div className="invoice_alert"></div>}</div>
    },
    {
      title: "Zamestnanci",
      onClick: () => { props.onEmployesClick() },
      icon: <MdPeopleAlt  size={"20px"}/>
    },
    {
      title: "Rezervácie",
      onClick: () => { props.onReservationsClick() },
      icon: <IoPeopleCircleSharp  size={"20px"}/>
    },
    {
      title: "Platby",
      onClick: () => { props.onPaymentsClick() },
      icon: <MdOutlinePayment  size={"20px"}/>
    },
    {
      title: "Ako na to",
      onClick: () => { props.onHowToClick() },
      icon: <FaQuestionCircle  size={"20px"}/>
    },
  ]

  useEffect(() => {
    if (props.businessEstablishment) {
      setLogoBadgeURL(props.businessEstablishment.logo)
    }
  }, [props.businessEstablishment])


  /* useEffect(() => {
    if (window.innerWidth <= 1000) {
      const nav_card = document.getElementById("NAV_CARD_ID");
      if (firstRender) {
        if (nav_card) {
          nav_card.style.height = "35px";
          nav_card.style.animation = "none";

        }
      }
      if (nav_card) {
        nav_card.style.height = headerOpen ? "320px" : "35px";
      }
    }
  }, [headerOpen]); */


  useEffect(() => {
    const handleResize = () => {
      const nav_card = document.getElementById("NAV_CARD_ID");

      if (window.innerWidth <= 1000) {
        if (firstRender) {
          if (nav_card) {
            nav_card.style.height = "35px";
            nav_card.style.animation = "none";
          }
        }
        if (nav_card) {
          nav_card.style.height = headerOpen ? "430px" : "35px";
        }
      } else {
        // Ak je obrazovka väčšia ako 1000px, môžeš resetovať štýly
        if (nav_card) {
          nav_card.style.height = "";
          nav_card.style.animation = "";
        }
      }
    };

    // Spustí sa hneď po mountnutí komponentu
    handleResize();

    // Pridá listener na zmenu veľkosti okna
    window.addEventListener("resize", handleResize);

    return () => {
      // Vyčistí event listener pri unmountnutí komponentu
      window.removeEventListener("resize", handleResize);
    };
  }, [headerOpen]);




  return (
    <>
      <div className="logo_wrap">
        <div
          className="logo_bg"
          style={user?.is_superuser ? {background: "transparent"} : {}}
        >
          {user?.is_superuser ? "" : <><img src={mojeqr_logo} alt="MojeQr logo" className="mojeqr_logo" /> <div className="business_establishment_name_mobile_label">{props.businessEstablishment?.name}</div></>}
          <img
            src={user?.is_superuser ? mojeqr_logo : `${process.env.REACT_APP_MOJEQR_API_URL}${logoBadgeURL}`}
            style={user?.is_superuser ? {width: "90%"} : {}}
            alt="Client logo"
            className="client_logo"
          />
          <div
            className='header_hamburger'
            style={{
              transform: !headerOpen ? "rotate(0deg)" : "rotate(180deg)"
            }}
          >
            <AiOutlineCloseCircle
              onClick={handleMenuOpen}
              style={{
                borderRadius: "8px"
              }}
              size={"45px"}
            />
            <TiThMenuOutline
              onClick={handleMenuOpen}
              style={{
                borderRadius: "8px"
              }}
              size={"45px"}
            />
          </div>
        </div>
        <div
          id="ADMIN_PANEL_LOADING_INDICATOR"
          className="loading_wrap"
        >
          {/* <span>Načítavam zmeny</span> */}
          <AiOutlineLoading
            id="ADMIN_PANEL_LOADING_INDICATOR"
            size={"35px"}
            color="#2C2C2C"
            /* color="white" */
            className="loading_icon_local"
          />
        </div>
      </div>
      <div className="name_wrap">
        <div className="name">
          { `${user?.first_name} ${user?.last_name}` }
        </div>
        <div className="email">
          {/* { user?.email } */}
          info@mojeqr.sk
          <FiCopy
            className="icon"
            /* onClick={() => { copyToClipboard(user?.email) }} */
            onClick={() => {
              copyToClipboard("info@mojeqr.sk")
              invokeAlertComponent("INFORMATION_ALERT_COMPONENT", `Skopírovali ste emailovu adresu ${"info@mojeqr.sk"}`)
            }}
          />
          <SiGmail
            className="icon"
            /* onClick={() => { copyToClipboard(user?.email) }} */
            onClick={() => { sendMail() }}
          />
          <FaFacebookSquare
            className="icon"
            /* onClick={() => { copyToClipboard(user?.email) }} */
            onClick={() => { window.open("https://www.facebook.com/profile.php?id=100089698576173") }}
          />
          <RiInstagramFill
            className="icon"
            /* onClick={() => { copyToClipboard(user?.email) }} */
            onClick={() => { window.open("https://www.instagram.com/mojeqr/") }}
          />
        </div>
      </div>
      <div className="settings_wrap">
        <div className="title_wrap">
          Nastavenia
        </div>
        <div className="settings_elements_wrap">
          <div
            className="settings_element_active_indicator"
            style={{
              top: `${getTopMargin(props.activeTabNumber)}px`
            }}
          />
          {
            settings_elements.map((option, index) =>
              <div
                className={`settings_element ${index === props.activeTabNumber ? "settings_element_active" : ""}`}
                key={index}
                onClick={() => {
                  option.onClick()
                  setHeaderOpen(false)
                }}
              >
                {option.icon}
                {option.title}
              </div>
            )
          }
        </div>
      </div>
      <div
        className="settings_element"
        onClick={() => {
          window.open(`${window.location.origin}/${props.businessEstablishment.path}`)
        }}
      >
        <ImMobile size={"20px"} />
        Zobraziť náhľad
      </div>
      <div
        className="settings_element logout_mobile_wrap"
        onClick={() => {
          handleMenuOpen()
          setTimeout(() => {
            props.onLogout()
          }, 400);
        }}
      >
        <IoLogOut size={"20px"} />
        Odhlásiť sa
      </div>
      {
        user?.is_superuser &&
        <div className="client_list_wrap">
          <div className="field_element" style={{ width: "250px"}}>
            <SelectorElement
              selectedElement={props.businessEstablishment?.uuid}
              list={
                props.clients.map(client => {
                  return {
                      title: client.name,
                      value: client.uuid
                  }
                })
              }
              onSelect={(new_type) => { props.onSetCurrentClient(new_type)}}
            />

          </div>
        </div>
      }
    </>
  )
}

export default NavigationCard